.creator,
.member,
.undefined {
  /* pc 768px以上 */

  /* メインカラム */

  h2 {
    margin: 5% 0;
    font-size: 1.2rem;
  }

  h3 {
    margin: 0 0 2% 0;
  }

  .btn {
    margin: 4% 0 !important;
  }

  .btn .back {
    background-color: #b6bec1;
  }

  table {
    color: #5a5a5a;
  }

  .main table td {
    padding: 5px 5px 5px 0;
  }

  .menu.member {
    margin: 20px;
  }

  th.product-name {
    width: 300px;
  }

  .more-button {
    text-align: end;
    padding: 0 0 5% 0 !important;
  }

  .information-box th,
  .information-box td {
    padding: 10px !important;
  }

  table {
    margin: none;
  }

  input.member {
    margin: 20px 0;
  }

  h2.message {
    margin: 10% 0 5% 0 !important;
  }

  img.user-icon {
    width: 50px;
    border-radius: 50%;
  }

  table.message td {
    padding: 10px !important;
  }

  table.message tr {
    background-color: #fff !important;
  }

  td.message-contents {
    border-bottom: 1px rgb(209, 209, 209) dotted;
  }

  .information-box table.message tr {
    border: none;
  }

  td.user-name {
    font-weight: bold;
  }
  @media screen and (max-width: 750px) {
    .information-box table.message td {
      display: inline-block;
    }

    td.user-name {
      width: 150px;
      font-size: 0.8rem;
    }
    td.send-time {
      font-size: 0.5rem;
    }
    table.message td {
      padding: 2px !important;
    }
  }

  .information-box table tr {
    border-bottom: 0.5px solid rgb(206, 222, 230);
  }

  table tr {
    background: #eff9fc;
  }

  table tr:nth-child(odd) {
    background: #fff;
  }

  .information-box.line {
    border-bottom: 0.5px solid rgb(194, 202, 206);
  }

  .information-box.line input,
  .information-box.line p,
  .information-box.line img,
  .information-box.line textarea {
    margin-bottom: 2% !important;
  }

  .detail-btn,
  .add-btn,
  .delete-btn,
  .edit-btn,
  .duplication-btn {
    width: 80px;
  }

  @media screen and (max-width: 750px) {
    input.save,
    input.add {
      margin: 0 10px;
    }

    .btn .back {
      margin: 10px 0;
    }

    h2 {
      font-size: 1rem !important;
    }

    h3 {
      font-size: 0.8rem !important;
    }

    .information-box th,
    .information-box td {
      padding: 5px !important;
    }

    .information-box table {
      margin: auto;
      width: 100%;
    }

    .information-box table th,
    .information-box table td {
      border-bottom: none;
      display: block;
    }

    .information-box th {
      background: #d9f1fb;
    }

    td.detail-btn {
      text-align: center;
      margin: 4% 0;
    }

    input.save,
    input.add,
    input.edit,
    input.detail,
    input.add,
    input.delete,
    input.duplication {
      width: 50%;
    }

    .detail-btn,
    .add-btn,
    .delete-btn {
      text-align: center;
      width: 100%;
    }
    table tr {
      background: #fff;
    }
  }

  /* セレクトメニュー */
  .cp_ipselect {
    overflow: hidden;
    width: 50%;
    margin: 2em auto;
    text-align: center;
  }

  .cp_ipselect select {
    width: 100%;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .cp_ipselect select::-ms-expand {
    display: none;
  }

  .cp_ipselect.cp_sl {
    position: relative;
    border-radius: 2px;
    border: 2px solid skyblue;
    border-radius: 5px;
    background: #ffffff;
    margin: 0;
  }

  .cp_ipselect.cp_sl::before {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid skyblue;
    pointer-events: none;
  }

  .cp_ipselect.cp_sl select {
    padding: 8px 38px 8px 8px;
    color: black;
  }

  input.wide {
    border: 2px solid skyblue;
    border-radius: 5px;
  }

  input.wide.category {
    width: 300px;
  }

  label,
  textarea {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  textarea {
    padding: 10px;
    max-width: 95%;
    line-height: 1.5;
    border-radius: 5px;
    border: 2px solid skyblue;
    width: 100%;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input.date {
    border: 2px solid skyblue;
    border-radius: 5px;
    width: 47%;
    line-height: 1.5;
    padding: 10px;
  }

  @media screen and (max-width: 750px) {
    input.wide.category {
      width: 200px;
    }

    .wide,
    .cp_ipselect {
      width: 100% !important;
    }
  }

  /* サイドメニュー */
  .side-menu {
    /* 左端に設置 */
    // margin: 60px 0px 60px 100px;
    width: 200px;
    border-top: 1px dotted #70b4cc;
  }

  .side-menu li a {
    display: block;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px dotted #70b4cc;
    text-decoration: none;
    color: #5a5a5a;
  }

  /* バナーエリア */
  .banner-area {
    /* 右端に設置 */
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
  }

  .banner-area div {
    margin-bottom: 20px;
  }

  .banner-area div a {
    display: block;
    height: 70px;
    line-height: 70px;
    background-color: #333;
    text-decoration: none;
    text-align: center;
    color: #fff;
  }

  /* スマホ 767px以下 */
  @media only screen and (max-width: 767px) {
    .wrap {
      /* position: relative;を解除 */
      position: static;
      width: 90%;
    }

    /* メインカラム */
    .main {
      width: 100%;
      /* サンプル用に高さを指定 */

      margin-left: 0;
      margin-bottom: 30px;
    }

    /* サイドメニュー */
    .side-menu {
      /* position: absolute;を解除 */
      position: static;
      width: 100%;
      margin: 30px 0;
    }

    /* バナーエリア */
    .banner-area {
      /* position: absolute;を解除 */
      position: static;
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .main {
      width: 100%;
      /* サンプル用に高さを指定 
        background-color: #ccc;*/
      margin: 10px auto;
    }

    .main img {
      width: 100%;
    }
  }

  /* バナーエリア詳細 */

  li.user-name {
    font-weight: bold;
    color: #85cde7;
  }
}
