.img {
  display: flex;
  background-image: cover;

  &.borderd {
    border: 1px solid #e0e0e0;
  }

  &.cover {
    background-size: cover;
  }

  &.square {
    padding-bottom: 100%;
    &.width {
      padding-bottom: 0;
    }
  }
  &.round {
    padding-bottom: 100%;
    border-radius: 50%;
    &.width {
      padding-bottom: 0;
    }
  }
  &.fullWidth {
    width: 100%;
  }
}
