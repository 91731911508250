.button {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 40px;
  background: #85cde7;
  padding: 5px 30px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  transition: all 0.4s;
  border-radius: 5px;
  font-weight: bold;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  gap: 10px;

  &.info {
    background: #b6bec1;
  }

  &.blackBlue {
    background: #69afc9;
  }

  &.secondary {
    background: #92a5d3;
  }

  &.tertiary {
    background: #f58db8;
  }
  &.loading {
    background: #ccc;
    cursor: not-allowed;
    pointer-events: none;
    &::before {
      display: flex;
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20px;
    }
  }
  &.disabled {
    background: #ccc;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.grow {
    flex-grow: 1;
  }
  &.shrink {
    flex-shrink: 1;
  }
  &.flex {
    display: flex;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
