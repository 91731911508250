.user {
  width: 100%;
  display: flex;
  flex-direction: column;
  .name {
    font-size: 1.7em;
  }
  .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 240px;
    gap: 20px;
    .image {
      width: 120px;
      height: 120px;
      flex: 0 0 120px;
      object-fit: cover;
      border-radius: 50%;
      display: block;
    }
    .description {
      flex: 1 1 auto;
      max-height: 100%;
      //overflow: scroll;
    }
    .plans {
      flex: 0 0 100px;
      display: flex;
      height: 30px;
      justify-content: space-around;
      align-items: center;
      padding: 10px 25px;
      color: #fff;
      font-weight: 600;
      background: #85cde7;
      border-radius: 10px;
      border: none;
      line-height: 2;
    }
  }
  .links {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    & > * {
      font-weight: bold;
    }
  }
  margin: 0 auto;
  text-align: left;
  border-bottom: solid 1px #b3b3b3;
  width: 100%;
}
.container {
  padding-top: 20px;
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
  .product {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 10px;
    .post {
      border: 3px solid #e8e5e5;
      height: 50px;
      display: flex;
      flex-direction: row;
      & > * {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 3px solid #e8e5e5;
        &:last-child {
          border-right: none;
        }
      }
    }
    .name {
      font-size: 20px;
    }
    .link {
      //   height: 40px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      & > * {
        display: flex;
        font-family: Arial;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        padding: 10px 20px;
      }
      .facebook {
        background-color: #2e4a88;
        box-shadow: 0 4px 0 #1b3d82;
        text-shadow: 0 -1px -1px #1b3d82;
      }
      .twitter {
        background-color: #008dde;
        box-shadow: 0 4px 0 #0078bd;
      }
      .google {
        background-color: #cc3622;
        box-shadow: 0 4px 0 #ba2714;
      }
      .favorite {
        background-color: #efefef;
        box-shadow: 0 4px 0 #acacac;
        color: #484848;
      }
    }
    .thumbnailcontainer {
      width: 100%;
      position: relative;
      &:before {
        content: " ";
        display: block;
        padding-top: 100%;
      }
      .thumbnail {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
      }
    }

    .description {
      text-align: left;
      font-size: 14px;
    }
    .price {
      text-align: left;
      font-weight: 600;
    }
    .files {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      .imgcontainer {
        position: relative;
        width: 50%;
        flex: 0 0 calc(50% - 10px);
        overflow: hidden;
        &:before {
          content: " ";
          display: block;
          padding-top: 100%;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: 2;
        }

        .file {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          filter: blur(10px);
          z-index: 1;
        }
      }
    }
  }
  .related {
    width: 100%;
    flex: 0 0 300px;
    height: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 750px) {
      flex: 1 1 auto;
      height: auto;
    }
    .text {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .products {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      .product {
        flex: 0 0 calc(50% - 10px);
        width: 150px;
        &:before {
          content: " ";
          display: block;
          padding-top: 100%;
        }
        display: block;
        position: relative;
        .thumbnail {
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .text {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #c4c2c2;
          padding: 5px;
        }
      }
    }
  }
}
