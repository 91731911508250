.root {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  border-bottom: 1px #5e5e5e solid;
  & .fullWidth {
    width: 100%;
  }
}
