html {
  height: 100%;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}
a {
  text-decoration: none;
}
i {
  font-family: "Font Awesome 5 Free";
}
.wrap {
  /* サイドメニューとバナーエリアの基点に設定 */
  position: relative;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  min-height: 500px;
}
#wrapper {
  margin: 0 10%;
}
/* pc 768px以上 */

.pc {
  display: block;
}

.sp {
  display: none;
}

.top-blank {
  padding: 20px 0 0 0;
}
ul.term-list {
  margin: 20px 0;
  li {
    margin: 10px 0;
  }
}

.red {
  color: red;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  .wrap {
    padding: 10px;
    width: auto;
  }
}

#wrapper {
  margin: 5%;
}

p {
  color: #5a5a5a;
  margin: 0 0 2% 0;
}

h2 {
  color: #5a5a5a;
  /* 文字色 */
  overflow: hidden;
  padding: 10px;
  /* 余白 */
  position: relative;
  margin: 5% 0;
}

h2:after {
  background-color: #a3c8f3;
  /* 1個目（一番左）のドットの色 */
  border-radius: 50%;
  content: "";
  margin-left: 15px;
  /* 最後の文字とドットとの余白 */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  /* ドットの幅 */
  height: 5px;
  /* ドットの高さ */
  box-shadow: 20px 0px 0px rgb(179, 182, 217),
    /* 2個目のドットの位置と色 */ 40px 0px 0px rgb(179, 214, 217),
    /* 3個目のドットの位置と色 */ 60px 0px 0px rgb(163, 224, 243),
    /* 4個目のドットの位置と色 */ 80px 0px 0px rgb(179, 217, 199),
    /* 5個目のドットの位置と色 */ 100px 0px 0px rgb(179, 187, 217),
    /* 6個目のドットの位置と色 */ 120px 0px 0px rgb(163, 224, 243),
    /* 7個目のドットの位置と色 */ 140px 0px 0px rgb(179, 217, 183),
    /* 8個目のドットの位置と色 */ 160px 0px 0px rgb(179, 192, 217),
    /* 9個目のドットの位置と色 */ 180px 0px 0px rgb(163, 176, 243),
    /* 10個目のドットの位置と色 */ 200px 0px 0px rgb(202, 235, 245),
    /* 11個目のドットの位置と色 */ 220px 0px 0px rgb(179, 194, 217);
  /* 12個目のドットの位置と色 */
}

h2.page-title {
  text-align: center;
}
h2.page-title:after {
  display: none;
}

h3 {
  color: #5a5a5a;
}

a {
  color: #70b4cc;
}

// 影響範囲全体だったので、適用したい箇所に直接指定orクラスを付与するようお願いします。
// a:hover {
//   filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//   opacity: 0.7;
// }

input.save,
input.add,
input.edit,
input.duplication,
input.detail {
  border: none;
  background: #85cde7;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin: 0 10px;
}

input.save:hover,
input.add:hover,
input.edit:hover,
input.detail:hover {
  background: #79bbd3;
}

input.delete {
  border: none;
  background-color: #f13e6b !important;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
.file-input {
  border: none;
}

Button.delete {
  background-color: #f13e6b !important;
}
@media screen and (max-width: 750px) {
  h2 {
    font-size: 1rem;
  }

  h2:after {
    width: 3px;
    /* ドットの幅 */
    height: 3px;
    /* ドットの高さ */
  }
}

/*ヘッダー*/
.iframe-header {
  position: relative;
  padding-bottom: 15%;
  height: 0;
  overflow: hidden;
}

.iframe-header iframe {
  width: 100%;
  height: 200px;
}

.header-nav-btn {
  width: 20%;
}

header {
  margin: 0 2%;
  color: #5a5a5a;
}

#header,
#header-creator,
#header-member01,
#header-member02 {
  border-top: 5px solid #d9f1fb;
  border-bottom: 5px solid #d9f1fb;
}

.pc.menu {
  display: flex;
  margin: 2% 0% 0 0%;
  height: 80px;
}

.pc.menu.creator,
.pc.menu.member {
  margin: 2% 2% 0 2%;
}

/* Header */
.menu {
  margin: 20px;
}
.inner-menu {
  display: flex;
  width: 30%;
  text-align: center;

  i {
    line-height: 50px;
    display: inline-block;
    font-size: 30px;
    vertical-align: middle;
  }

  a {
    text-decoration: 0;
  }
  p.menu-name {
    overflow: hidden;
    font-size: 12px;
  }

  .menu-icon {
    // アイコンなしがはみ出るので、font-sizeを小さくした。
    overflow: hidden;
    font-size: 5px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
  }
}

@media screen and (max-width: 750px) {
  .inner-menu {
    width: 100%;
  }
}

/* ドロップダウンメニュー */

.menu-open > li {
  float: left;
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #4f4f4f;
}
.menu-open > li a {
  display: block;
  color: #4f4f4f;
}

.menu-open > li a:hover {
  color: #999;
}

ul.menu__second-level {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

ul.menu__third-level {
  visibility: hidden;
  opacity: 0;
}

ul.menu__fourth-level {
  visibility: hidden;
  opacity: 0;
}

.menu-open > li:hover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* floatクリア */
.menu-open:before,
.menu-open:after {
  content: " ";
  display: table;
}

.menu-open:after {
  clear: both;
}

.menu-open {
  *zoom: 1;
}

.menu-open > li.menu__single {
  position: relative;
}

li.menu__single ul.menu__second-level {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 200px;
  background: #d9f1fb;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 10px;
}

li.menu__single:hover ul.menu__second-level {
  top: 85px;
  visibility: visible;
  opacity: 1;
  z-index: 99;
}

.btn.list-add {
  display: flex;
  margin: 0;

  .page-title {
    width: 50%;
    margin: 0;
    text-align: left;
  }
  a {
    width: 50%;
    text-align: end;
  }
}
@media screen and (max-width: 750px) {
  li.menu__single ul.menu__second-level {
    width: 200px;
  }
  .btn.list-add {
    display: flex;
    margin: 0;

    .page-title {
      width: 40%;
      margin: 0;
      text-align: left;
    }
    a {
      width: 60%;
      text-align: end;
    }
  }

  .tab_content {
    padding: 0;
  }
}

.headline {
  line-height: 100px;
  float: left;
  font-size: 30px;
  width: 60%;
}

.headline02 {
  line-height: 100px;
  float: left;
  font-size: 30px;
  width: 70%;
  margin: 10px 0;
}

.header-button02 {
  width: 20%;
}

.headline img {
  width: 30%;
  margin: 0 0 0 10%;
}

.headline02 img {
  width: 200px;
}

@media screen and (max-width: 750px) {
  .headline02 {
    width: 100%;
    text-align: center;
  }

  h1 {
    height: 50px;
  }
  .header-nav-btn.member-header {
    width: 25%;
  }
}

.nav-list {
  float: left;
  list-style: none;
  width: 80%;
  text-align: end;
}

.nav-list-item {
  list-style: none;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

i {
  color: #a2edfe;
}

i.fa.fa-user {
  color: #fff;
  padding: 0 10px 0 0;
}

.login-button {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 0 0%;
  max-width: 240px;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  background: #85cde7;
  border-radius: 8px;
  border: none;
}

.login-button:hover {
  background: #a6e6f3;
}

.logout-button {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 0 30%;
  max-width: 240px;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  background: #85cde7;
  border-radius: 8px;
  border: none;
}

/*ヘッダーボタン*/
.transition-button {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 240px;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  border-radius: 50px;
  border: none;
  font-size: 0.8rem;
}

.register-button {
  background: #ff9de8d7;
}

.register-button:hover {
  background: #ffb4edd7;
}

.member-button {
  background: #74e16ad7;
}

.member-button:hover {
  background: #80ee94d7;
}

.creator-button {
  background: #65d6e5d7;
}

.creator-button:hover {
  background: #b3e9f4d7;
}

/*ハンバーガーメニュー*/
.menu-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: #9ad8f3;
}
.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: "";
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}
.menu-btn span:before {
  bottom: 8px;
}
.menu-btn span:after {
  top: 8px;
}

#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(
    255,
    255,
    255,
    0
  ); /*メニューオープン時は真ん中の線を透明にする*/
}
#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}
#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(
    255,
    255,
    255,
    0
  ); /*メニューオープン時は真ん中の線を透明にする*/
}
#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}
#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}
#menu-btn-check {
  display: none;
}

.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  background-color: #9ad8f3;
}
.menu-content ul {
  padding: 70px 10px 0;
  text-align: left;
}
.menu-content ul li {
  border-bottom: solid 1px #ffffff;
  list-style: none;
}
.menu-content ul li a {
  display: block;
  width: 100%;
  font-size: 15px;
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
  padding: 9px 15px 10px 0;
  position: relative;
}
.menu-content ul li a::before {
  content: "";
  width: 7px;
  height: 7px;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  transform: rotate(45deg);
  position: absolute;
  right: 11px;
  top: 16px;
}
.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%; /*leftの値を変更してメニューを画面外へ*/
  z-index: 80;
  background-color: #9ad8f3;
  transition: all 0.5s; /*アニメーション設定*/
}

#menu-btn-check:checked ~ .menu-content {
  left: 0; /*メニューを画面内へ*/
}

.sp table {
  margin: 3% 0 !important;
}

.sp h3 {
  color: #2e5a83;
}
@media screen and (min-width: 1500px) {
  .register-button {
    margin: 0 0 0 0%;
  }
}

@media screen and (max-width: 1500px) {
  .register-button {
    margin: auto;
  }
}

.register p {
  margin: 20px;
}

.header-button {
  display: contents;

  a {
    padding: 5px;
  }
}

@media screen and (max-width: 750px) {
  .pc.menu {
    display: none;
  }

  .headline img {
    margin: 0;
  }

  table {
    margin: 2% 0;
  }

  .register-button {
    margin: 0px !important;
    padding: 10px 20px;
  }

  .sp th {
    text-align: center;
  }

  th.header-logo {
    width: 40%;
  }

  th.header-logo img {
    width: 80%;
  }

  th.header-login,
  th.header-register {
    width: 30%;
  }

  th.header-logo {
    width: 50%;
  }

  th.header-logo img {
    width: 80%;
  }

  th.header-loout {
    width: 20%;
  }
  .side-menu {
    display: none;
  }

  button.transition-button.creator-button,
  button.transition-button.member-button {
    font-size: 0.5rem;
    margin: auto;
  }
}

/*フッター*/
.iframe-footer {
  position: relative;
  padding-bottom: 40%;
  height: 0;
  overflow: hidden;
}

.iframe-footer iframe {
  width: 100%;
  height: 360px;
}

.footer {
  color: #5a5a5a;
  background: #d9f1fb;
  padding: 50px 10px;
  top: 100vh;
  font-size: 0.8rem;
}

.footer a {
  color: #5a5a5a;
  text-decoration: none;
}

.footer li a:hover {
  text-decoration: underline;
}

.footer .footer-wrap {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .footer-wrap h3 {
  margin: 0 0 10px 0;
  padding: 0;
  border-bottom: 1px #c4c4c4 solid;
  font-size: 0.8rem;
}

.footer .footer-wrap p {
  margin: 0;
  padding: 0 0 20px 0;
}

.footer .footer-wrap .box {
  width: 45%;
}

.footer .footer-wrap .box ul {
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;
}

.footer .footer-wrap .copyright {
  width: 100%;
}
p.copyright {
  text-align: center;
  margin: 50px 0 0 0 !important;
}

@media only screen and (max-width: 599px) {
  .footer .footer-wrap {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .footer .footer-wrap h3 {
    border: none;
  }

  .footer .footer-wrap .box {
    width: 100%;
  }

  .footer .footer-wrap .box ul {
    border-top: 1px #c4c4c4 solid;
  }

  .footer .footer-wrap .box ul li a {
    display: block;
    padding: 5px 15px;
    border-bottom: 1px #c4c4c4 solid;
  }
}

/*スライダー*/

.sp img {
  width: 50%;
}

h1.headline {
  margin: 10% auto;
  width: 100%;
  text-align: center;
}

.slider-wrap {
  width: 100%;
  margin: auto;
  position: relative;
}

.slider .slick-slide {
  overflow: hidden;
}

.slider .slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

.dots {
  margin: 0 auto 5% auto;
}

.dots .slick-dots {
  text-align: center;
}

.dots .slick-dots li {
  display: inline-block;
  width: 100px;
  height: 6px;
  margin: 4px;
  background: #eaeaea;
  cursor: pointer;
}

.dots .slick-dots button {
  display: block;
  width: 0%;
  height: 6px;
  margin: 0;
  font-size: 0;
  padding: 0;
  border: 0;
  opacity: 0;
  outline: none;
  background: #70b4cc;
  cursor: pointer;
}

.dots .slick-dots .slick-active button {
  background: #70b4cc;
  opacity: 1;
}

@media screen and (max-width: 750px) {
  h1.headline {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .slider-wrap {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .dots .slick-dots li {
    width: 20%;
  }

  /* .slider .slick-slide img {
        height: 200px;
    }*/
}

/* 3カラム */
/* カードレイアウト部分をラッピングし、
Flexboxを指定"space-between"で各アイテムを均等に配置し、
最初と最後のアイテムを端に寄せます。*/
.item-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin: 2% auto;
  max-width: 960px;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  gap: 10px;
}

/* リンクテキストの下線を非表示 */
a.item-link {
  text-decoration: none;
  padding: 10px 0;
  @media screen and (max-width: 750px) {
    width: 46%;
  }
}

/* カードレイアウト内の画像を幅いっぱいに表示 */
.item-wrap img {
  display: block;
  max-width: 80%;
  height: auto;
  margin: 5% auto 0 auto;
}

.item-figure {
  margin: 0;
  padding: 0;
}

/* カードレイアウトのタイトル部分 */
.item-title {
  margin: 0.6em 0 0;
  color: #333;
  text-align: center;
  font-size: 1em;
  width: 100%;
}

/* カードレイアウトの説明文部分 */
.item-text-tax {
  margin: 0;
  padding: 1em;
  color: #818181;
}

/* カードレイアウトを1カラムで配置 */
.item-list {
  margin: 0.5em auto;
  padding: 0;
  border-radius: 5%;
  width: 45%;
  background: #edf8ff;
  box-shadow: 0 4px 8px rgba(124, 137, 158, 0.08);
}

/* 画面幅768px以上の場合カードレイアウトを2カラムで配置 */
@media all and (min-width: 768px) {
  .item-list {
    margin: 0.5em 0;
    width: calc(96% / 2);
    /* 96%幅を2で割るという指定 */
  }
}

/* 画面幅992px以上の場合カードレイアウトを3カラムで配置 */
@media all and (min-width: 992px) {
  .item-list {
    width: calc(96% / 4);
    /* 96%幅を3で割るという指定 */
  }

  /* 最後の行が3列より少ない場合左寄せにレイアウトさせる */
  #itemlayout-wrap::after {
    content: "";
    display: block;
    width: calc(96% / 4);
  }
}

.more-button {
  text-align: end;
  padding: 0 15% 5% 0;
}

.btn-flat-more {
  position: relative;
  display: inline-block;
  font-weight: bold;
  padding: 0.25em 0.5em;
  text-decoration: none;
  color: #70b4cc;
  transition: 0.4s;
}

.btn-flat-more:hover {
  color: #9bd4e9;
}

.item-list {
  position: relative;
}

p.category {
  position: absolute;
  top: 0;
  /*画像の左上に配置*/
  top: 3%;
  left: 10%;
  /*余計な隙間を除く*/
  color: white;
  /*文字を白に*/

  /*背景色*/
  font-size: 15px;
  line-height: 1;
  /*行高は1に*/
  padding: 5px 10px;
  /*文字周りの余白*/
}

.nwe {
  background: skyblue;
}

.user {
  background: rgb(249, 165, 244);
}

.product {
  background: rgb(253, 209, 114);
}

/*お問い合わせ*/
html {
  overflow-y: scroll;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.information-box table tr:last-child {
  border-bottom: none;
}
span.caution {
  font-size: 0.8rem;
  color: #6d6d6d;
}

.caution {
  font-size: 0.8rem;
  color: #6d6d6d;
  width: 100%;
}
.title-padding {
  padding: 0;
}

.caution-contents {
  padding: 0 0 2% 0;
}

.information-box label {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .information-box label {
    width: 90%;
  }
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: "";
}

object,
embed {
  vertical-align: top;
}

hr,
legend {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

img,
abbr,
acronym,
fieldset {
  border: 0;
}

li {
  list-style-type: none;
}

sup {
  vertical-align: super;
  font-size: 0.5em;
}

img {
  vertical-align: top;
}

i {
  font-style: normal;
}

/*新規登録ページ*/

.sign-up {
  margin: auto;
  width: 500px;
}

.sign-up input.wide {
  width: 80%;
}
.sign-up input.date {
  width: 75%;
}

.sign-up .btn {
  margin: 10% auto !important;
}
@media only screen and (max-width: 768px) {
  .sign-up {
    margin: auto;
    width: auto;
  }
}

/*お問い合わせデザインcss↓*/
p.title {
  margin: 0;
}

p.check {
  font-size: 0.8rem;
}
.box_contact {
  max-width: 900px;
  margin: 0 auto;
}
select.business {
  border: 2px solid skyblue;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .box_contact {
    width: 95%;
  }

  .more-button {
    text-align: end;
    padding: 0 0 5% 0;
  }
}

.box_contact form {
  width: 100%;
}

.box_contact form ul {
  width: 100%;
}

.box_contact form ul li {
  position: relative;
  overflow: hidden;
  border: 1px solid #d9f1fb;
  margin: 0.5em 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  .box_contact form ul li {
    display: -webkit-flex;
    display: flex;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 10px;
  }
}

@media print {
  .box_contact form ul li {
    display: -webkit-flex;
    display: flex;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
}

.ie8 .box_contact form ul li {
  display: -webkit-flex;
  display: flex;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}

.box_contact form ul li .title {
  position: relative;
  width: 30%;
  font-weight: normal;
  padding: 1em;
  position: relative;
  color: #5a5a5a;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  .box_contact form ul li .title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media print {
  .box_contact form ul li .title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}

.ie8 .box_contact form ul li .title {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.box_contact form ul li .title:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #d9f1fb;
  z-index: 0;
}

@media only screen and (max-width: 768px) {
  .box_contact form ul li .title {
    text-align: center;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.2);
    padding: 0.8em 0.2em;
  }
}

.box_contact form ul li .title em {
  position: relative;
  z-index: 1;
}

.box_contact form ul li .title span {
  background: #ea947d;
  padding: 0 0.3em;
  color: #fff;
  margin-left: 0.5em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.box_contact form ul li .box_det {
  width: 70%;
  position: relative;
  padding: 1em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .box_contact form ul li .box_det {
    padding: 1.5em 0.5em;
    display: block;
    width: 100%;
  }
}

.box_contact form ul li .box_br {
  display: block;
}

.box_contact form ul li select,
.box_contact form ul li textarea,
.box_contact form ul li .wide,
.wide {
  width: 50%;
  height: 3em;
  padding: 0.5em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.box_contact form ul li textarea {
  height: 10em;
  width: 100%;
}

/*プライバシーのデザインcss↓*/
.con_pri {
  max-width: 700px;
  margin: 0 auto;
}

.panel-body p {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .con_pri {
    width: 95%;
  }
}

.con_pri .box_pri {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #cdcdcd;
  background: #f7f7f7;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 20px 55px;
}

.common-title {
  font-size: 120%;
  color: #4fb9df;
  margin: 0 0 20px 0;
  padding: 20px 15px;
  background-color: #eefaff;
  border-left: 5px solid #85cde7;
  font-weight: bold;
}

h1.top-title {
  font-size: 1.5em;
  color: #666;
  font-weight: normal;
  padding: 20px 0;
  font-weight: bold;
}

.kiyaku p {
  line-height: 1.2;
  padding-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .con_pri .box_pri {
    margin-top: 4%;
    padding: 3%;
  }

  .common-title {
    font-size: 0.8rem;
    padding: 10px 15px;
  }

  .kiyaku p {
    line-height: 1.8;
    padding-bottom: 10px;
    font-size: 0.6rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .con_pri .box_pri {
    padding: 4%;
  }
}

.con_pri .box_pri .box_tori {
  text-align: left;
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .con_pri .box_pri .box_tori {
    margin-top: 4%;
  }
}

.con_pri .box_pri .box_tori h4 {
  font-weight: normal;
  margin-bottom: 30px;
  font-size: 150%;
}

@media only screen and (max-width: 768px) {
  .con_pri .box_pri .box_tori h4 {
    margin-bottom: 4%;
  }
}

.con_pri .box_pri .box_tori .txt {
  padding: 0 20px;
}

@media only screen and (max-width: 768px) {
  .con_pri .box_pri .box_tori .txt {
    padding: 0;
  }
}

.con_pri .box_pri .box_num {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .con_pri .box_pri .box_num {
    margin-top: 5%;
  }
}

.con_pri .box_pri .box_num h4 {
  font-weight: normal;
  font-size: 113%;
}

.con_pri .box_pri .box_num .txt {
  padding: 10px 0 0 20px;
}

@media only screen and (max-width: 768px) {
  .con_pri .box_pri .box_num .txt {
    padding: 3% 0 0 3%;
  }
}

.box_check {
  text-align: center;
  margin: 1em auto;
}

.box_check label {
  display: inline-block;
}
.box_check label span {
  margin-left: 0.3em;
}

.btn {
  text-align: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: contents;
  }
}

.btn input,
.btn button {
  background: #85cde7;
  padding: 10px 30px;
  margin: 0 10px;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 5px;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    margin: 0 0 10px 0;
    font-size: 1rem;
  }
}

input.add {
  background: #ff99d3 !important;
}

@media screen and (max-width: 750px) {
  .btn.list-add input.add {
    width: 100%;
    padding: 10px 5px;
  }

  .earnings-btn {
    margin: 0;
    width: 50%;
  }
}

.btn button span {
  margin: 0;
}

.btn input:hover,
.btn button:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}

.headline-login {
  text-align: center;
  padding: 2%;
}

.headline-login img {
  width: 20%;
}

.login-page {
  width: 360px;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #70b4cc;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}

.form .message {
  margin: 15px 0 0 0 !important;
  color: #b3b3b3;
  font-size: 12px;
}

.form .message a {
  color: #70b4cc;
  text-decoration: none;
}

.form .register-form {
  display: none;
}

p.form-text {
  padding: 0 0 20px 0;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}

.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}

.container .info {
  margin: 50px auto;
  text-align: center;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.container .info span .fa {
  color: #ef3b3a;
}

.register {
  border-top: 2px dotted #70b4cc;
  margin: 10% 0;
}

.register button {
  background: #ff9de8d7;
}

.register button:hover {
  background: #ffb4edd7;
}

.add-button {
  margin: 20px auto;
}

button.add-button {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1% 2%;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  background: #ff9de8d7;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
}

button.add-button:hover {
  background: #ffb4edd7;
}

.login-form.reset p {
  margin: 10% 0;
  text-align: left;
}
.login-form.reset p.message {
  text-align: center;
}
@media screen and (max-width: 750px) {
  .headline-login {
    padding: 5%;
  }

  .headline-login img {
    width: 50%;
  }

  .form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 100%;
    margin: 0 auto 100px;
    padding: 20px;
    text-align: center;
  }

  .login-page {
    width: 100%;
    padding: 8% 0 0;
    margin: auto;
  }

  button.add-button {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 300px;
    padding: 5% 5%;
    color: #fff;
    transition: 0.3s ease-in-out;
    font-weight: 600;
    background: #ff9de8d7;

    border: none;
    margin: 10% auto;
  }
}
/* index画面 */
.overview {
  text-align: center;
}
img.overview-logo {
  width: 250px;
  padding: 20px 0;
}

.overview-main {
  display: flex;
  margin: 40px;
}
h2.overview-title {
  color: #45b6df;
  margin: 40px;
  font-size: 2rem;
  i {
    color: #45b6df;
  }
}

h2.overview-title::after {
  display: none;
}
h3.overview {
  background-color: #45b6df;
  color: #fff;
  border-radius: 5px;
  margin: 0;
}
.overview-text {
  width: 33.333%;
  padding: 0 10px;
}

.overview-point {
  padding: 20px;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
}

@media screen and (max-width: 750px) {
  .overview-main {
    display: block;
    margin: 20px;
  }
  .overview-text {
    width: 100%;
  }

  .title-blank {
    padding: 0 0 20px 0 !important;
  }
}
.information-box.information-border.add-title {
  background-color: #f58eb8;
  h3 {
    text-align: center;
    margin: auto !important;
    color: #fff;
  }
}

.boxcolor {
  border-radius: 10px;
  padding: 15px;
  background: #eff9fc;
  margin-top: 2%;
}
.attention {
  margin: 0 0 0 30%;
}

ul.attention {
  margin: 0 0 0 10%;
}

.information-box {
  padding: 2% 0;
}
.information-box p {
  margin: 0 !important;
}

.information-button {
  text-align: right;
}

.information-wrap {
  background: rgb(255, 255, 255);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  padding: 50px;
  border-radius: 10px;
  margin: auto;
}
.information-border {
  border-bottom: dotted 1px #d3d3d3;
}

.information-contents {
  display: flex;
}

.last-contents {
  padding: 2% 0;
}
.shop-explanation textarea {
  height: 100px;
}
h3 {
  width: 30%;
  line-height: 1;
  span {
    font-size: 2px;
    line-height: 2;
  }
}
p.pass-text {
  margin: 0;
  font-size: 12px;
  input {
    margin: 5px;
  }
}

span.required {
  background-color: #f58eb8;
  color: #fff;
  padding: 5px;
  font-size: 0.8rem !important;
  border-radius: 5px;
  margin: 0 0 0 2%;
}
.account-icon {
  text-align: center;

  h3 {
    text-align: left;
  }
  img {
    width: 100px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .information-wrap {
    width: 75%;
    padding: 20px;

    .information-contents {
      display: block;
    }
    .information-border {
      padding: 5% 0;
    }
    h3 {
      width: 100%;
      padding: 0 0 5px 0;
    }
  }
  .information-button {
    text-align: center;
  }

  .account-icon {
    img {
      width: 100px !important;
      border-radius: 50%;
    }
  }

  .attention {
    margin: 0;
  }
}

/*商品ページ*/

* {
  color: #666363;
}

.wrap {
  overflow: hidden;
}

/* SNSボタン */

.sosyal {
  margin: 50px auto;
  text-align: left;
}
.facebook {
  background-color: #2e4a88;
  box-shadow: 0 4px 0 #1b3d82;
  text-shadow: 0 -1px -1px #1b3d82;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.facebook:hover {
  background-color: #354f84;
}
.facebook:active {
  top: 2px;
  box-shadow: 0 2px 0 #1b3d82;
}
.twitter {
  background-color: #008dde;
  box-shadow: 0 4px 0 #0078bd;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.twitter:active {
  top: 2px;
  box-shadow: 0 2px 0 #0078bd;
}
.twitter:hover {
  background-color: #1397d8;
}
.google {
  background-color: #cc3622;
  box-shadow: 0 4px 0 #ba2714;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.google:active {
  top: 2px;
  box-shadow: 0 2px 0 #ba2714;
}
.google:hover {
  background-color: #d04829;
}

.favorite-items {
  background-color: #efefef;
  box-shadow: 0 4px 0 #acacac;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: rgb(72, 72, 72);
  border-radius: 5px;
  padding: 12px 30px;
}
.twitter:active {
  top: 2px;
  box-shadow: 0 2px 0 #7e7e7e;
}
.twitter:hover {
  background-color: #a3a3a3;
}

/*  */

.items-top {
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.top-name-plate {
  margin: 0 auto;
  text-align: left;
  border-bottom: solid 1px #b3b3b3;
  width: 100%;
}

.top-nav-box {
  display: flex;
  margin: 0px auto;
}

ul.top-nav-box {
  list-style: none;
  overflow: hidden;
}
li.top-nav-box {
  text-align: center;
  float: left;
  line-height: 50px;
  margin-right: 2px;
}

li.top-nav-box a {
  text-decoration: none;
  font-weight: bold;
  padding: 20px;
}

.top-name-box {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;
}
@media only screen and (max-width: 768px) {
  .items-textbox {
    width: 100%;
  }
  li.top-nav-box a {
    display: block;
    margin-right: 0%;
    padding: 0px 5px;
  }
}

.items-imgbox,
.items-textbox {
  margin: 15px;
  padding: 10px;
  font-size: 14px;
  max-width: 50%;
}
.items-imgbox,
.fan-button {
  width: 20%;
  margin: 30px 0;
  text-align: center;
}
.items-textbox {
  width: 60%;
}
a.plan-btn {
  margin: 10px 0;
  justify-content: space-around;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  font-weight: 600;
  background: #85cde7;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  line-height: 2;
}

span.plan-btn {
  font-weight: 100;
  color: #fff;
}

.items-name {
  font-size: 1.7em;
}

.top-name-img {
  border-radius: 250px;
  width: 120px;
  display: block;
}

.post-main {
  margin: 15px;
  padding: 15px;
}

.post-items {
  display: flex;
  justify-content: center;
}

.post-items1 {
  display: block;
  width: 65%;
  margin: 15px;
  padding: 15px;
}
.post-items2 {
  display: block;
  width: 100%;
  margin: 15px;
  padding: 15px;
}

.post-items3 {
  display: block;
  /* width: 100%; */
  margin: 15px;
  padding: 15px;
}

h4.item-ttl {
  margin: 15px;
  font-size: 20px;
}

.item-text-box {
  margin: 15px;
  font-size: 14px;
}

.plan-box {
  display: block;
  width: 60%;
  margin: 15px auto;
  padding: 15px;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 400;
  font-size: 20px;
  background: #f58eb8;
  border-radius: 8px;
}

.side-plan {
  border: solid 3px #a0d9f1; /*線*/
  border-radius: 10px; /*角の丸み*/
}

.post-ttl {
  justify-content: center;
}

.post-ttl a {
  display: block;
  height: 20px;
  padding: 10px;
}

.post-ttl ul {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}

.post-ttl li {
  width: 250px;
  height: 50px;
}

.list1 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
}
.list2 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
}

.list3 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
  border-right: 3px solid #e8e5e5;
}

.tumbnail {
  display: block;
  margin: 5px;
}

.tumbnail img {
  width: 150px;
}

.side-post {
  display: flex;
  flex-wrap: wrap;
  width: 320px;
}

.tumbnail {
  /*親div*/
  position: relative;
}

.tumbnail p {
  display: block;
  position: absolute;
  color: #494949;
  font-size: 15px;
  top: 79%;
  background-color: #c4c2c2;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;
}

.plan-box-side {
  text-align: center;
  font-weight: bold;
  display: block;
  width: 60%;
  margin: 15px auto;
  padding: 15px;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-size: 20px;
  background: #f58eb8;
  border-radius: 8px;
}

.user-post-message {
  text-align: left;
  margin: auto;
}

ul.header-menu {
  display: flex;
  font-size: 14px;
}

.header-menu li {
  padding: 10px;
  width: 190px;
  display: block;
  text-align: center;
}

.header-menu li + li {
  position: relative;
}
.header-menu li + li::before {
  content: "";
  display: block;
  height: 1em;
  border-left: 1px solid #999;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (max-width: 750px) {
  .plan-box {
    border: solid 3px #a0d9f1;
    border-radius: 10px;
    display: block;
    width: 80%;
  }

  .side-plan {
    border: solid 3px #a0d9f1;
    border-radius: 10px;
    display: none;
  }

  .list2 {
    display: none !important;
  }

  .post-ttl ul {
    display: flex;
    width: 250px;
    margin: auto;
    vertical-align: middle;
    height: 100px;
  }

  .post-ttl li {
    width: 250px;
    height: 50px;
    display: inline-block;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }

  .items-name {
    font-size: 1.7em;
    margin: 7px;
  }

  .post-ttl {
    margin-top: 15px;
  }

  .top-name-box {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .top-nav-box a {
    display: block;
    margin-right: 1%;
    padding: 7px;
    text-decoration: none;
    font-size: 15px;
  }

  .post-items {
    display: block;
  }

  .top-name-plate {
    margin: 0 auto;
    padding: 0px;
  }

  .item-img img {
    width: 230px;
  }

  .fan-button {
    height: 50px;
    width: 200px;
    margin: auto;
    text-align: center;
  }

  .post-main {
    margin: 0px;
    padding: 0px;
  }

  .post-items1 {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .top-name-img {
    text-align: center;
    margin: auto;
  }
}

/* SNSボタン */

.sosyal {
  margin: 50px auto;
  text-align: left;
}
.facebook {
  background-color: #2e4a88;
  box-shadow: 0 4px 0 #1b3d82;
  text-shadow: 0 -1px -1px #1b3d82;
  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.facebook:hover {
  background-color: #354f84;
}
.facebook:active {
  top: 2px;
  box-shadow: 0 2px 0 #1b3d82;
}
.twitter {
  background-color: #008dde;
  box-shadow: 0 4px 0 #0078bd;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.twitter:active {
  top: 2px;
  box-shadow: 0 2px 0 #0078bd;
}
.twitter:hover {
  background-color: #1397d8;
}
.google {
  background-color: #cc3622;
  box-shadow: 0 4px 0 #ba2714;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  padding: 12px 30px;
}
.google:active {
  top: 2px;
  box-shadow: 0 2px 0 #ba2714;
}
.google:hover {
  background-color: #d04829;
}

.favorite-items {
  background-color: #efefef;
  box-shadow: 0 4px 0 #acacac;

  display: inline;
  position: relative;
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: rgb(72, 72, 72);
  border-radius: 5px;
  padding: 12px 30px;
}
.twitter:active {
  top: 2px;
  box-shadow: 0 2px 0 #7e7e7e;
}
.twitter:hover {
  background-color: #a3a3a3;
}

/*タブ*/
.tabs {
  margin-top: 50px;
  padding-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 700px;
  margin: 0 auto;
}

/*タブのスタイル*/
.tab_item {
  width: 50%;
  height: 50px;
  border-bottom: 3px solid #70b4cc;
  background-color: #d9d9d9;
  line-height: 50px;
  font-size: 16px;
  text-align: center;
  color: #565656;
  display: block;
  float: left;
  text-align: center;
  font-weight: bold;
  transition: all 0.2s ease;
}
.tab_item:hover {
  opacity: 0.75;
}

/*ラジオボタンを全て消す*/
input[name="tab_item"] {
  display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
  display: none;
  padding: 40px 40px 0;
  clear: both;
  overflow: hidden;
}

/*選択されているタブのコンテンツのみを表示*/
#tab01:checked ~ #tab01_content,
#tab02:checked ~ #tab02_content {
  display: block;
}

/*選択されているタブのスタイルを変える*/
.tabs input:checked + .tab_item {
  background-color: #70b4cc;
  color: #fff;
}

@media (max-width: 750px) {
  .tabs {
    width: 100%;
  }
}

/*お知らせページ*/

.noti-table {
  width: 100%;
  min-width: 300px;
}

.noti-table3 td {
  margin: 5px;
  padding: 5px;
}

.noti-table {
  height: 50px;
  border: solid 0.5px #bebebe;
  border-collapse: collapse;
}

.noti-table tr:nth-child(odd) td {
  background-color: #eaf6fd;
}
.noti-table tr:nth-child(even) td {
  background-color: #ffffff;
}

.noti-ymd {
  font-size: 12px;
  text-align: left;
  margin-left: 15px;
}

.noti-txt {
  font-size: 15px;
  text-align: left;
  margin-left: 15px;
}

.noti-txt2 {
  font-size: 15px;
  text-align: left;
  margin: 15px;
}

.noti-table2 td {
  height: 60px;
  text-align: center;
  border: 0.5px solid #bebebe;
  border-collapse: separate;
  border-spacing: 0;
  background: #85cde7 !important;
  font-size: 18px;
  color: #ffffff;
}

.noti-table3 td {
  margin: 10px;
  padding: 10px;
}

.noti-ymd2 {
  margin-bottom: 10px;
}

.noti-ymd3 {
  padding-top: 10px;
  font-size: 14px;
}

.noti-ttl {
  font-size: 18px;
  margin: 10px;
  border-bottom: 0.5px dotted;
  padding-bottom: 10px;
}

.favorite-page {
  border: solid 1px #c8c8c8;
  display: block;
  width: 65%;
  margin: 15px;
  padding: 15px;
}

.favopage-ttl {
  border: solid 1px #bebebe;
  margin: 10px;
  width: 100%;
}

.favo-table {
  height: 50px;
  border: solid 0.5px #bebebe;
  border-collapse: collapse;
}

.favo-table1 th {
  height: 60px;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  border: 0.5px solid #bebebe;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
}

.favo-table2 td {
  height: 60px;
  text-align: center;
  border: 0.5px solid #bebebe;
  border-collapse: separate;
  border-spacing: 0;
  background: #85cde7;
  font-size: 18px;
  color: #ffffff;
}

.favo-table3 td {
  margin: 20px;
  padding: 20px;
  background: #fff;
}

.favo-post {
  background-color: #0078bd;
}

h4.item-ttl {
  margin: 15px;
  font-size: 20px;
}

.item-text-box {
  margin: 15px;
  font-size: 14px;
}

.plan-box {
  width: 60%;
  margin: 15px auto;
  padding: 15px;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 400;
  font-size: 20px;
  background: #f58eb8;
  border-radius: 8px;
}

.side-plan {
  border: solid 3px #a0d9f1; /*線*/
  border-radius: 10px; /*角の丸み*/
}

.post-ttl {
  justify-content: center;
}

.post-ttl a {
  display: block;
  height: 20px;
  padding: 10px;
}

.post-ttl ul {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}

.post-ttl li {
  width: 250px;
  height: 50px;
}

.list1 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
}
.list2 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
}

.list3 {
  border-top: 3px solid #e8e5e5;
  border-bottom: 3px solid #e8e5e5;
  border-left: 3px solid #e8e5e5;
  border-right: 3px solid #e8e5e5;
}

.tumbnail {
  display: block;
  margin: 5px;
}

.tumbnail img {
  width: 150px;
}

.side-post {
  display: flex;
  flex-wrap: wrap;
  width: 320px;
}

.favorite-list-box {
  display: flex;
  flex-wrap: wrap;
}

.tumbnail {
  /*親div*/
  position: relative;
}

.tumbnail {
  display: block;
  position: absolute;
  color: #373737;
  font-size: 12px;
  top: 83%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;
}

.tumbnail2 {
  display: block;
  margin: 9px;
  background: #f2f2f2;
}

.tumbnail2 img {
  width: 180px;
  vertical-align: middle;
}

.tumbnail2 div {
  display: block;
  color: #373737;
  font-size: 13px;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  width: 140px;
  margin: auto;
}

.tumb-disc img {
  width: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  vertical-align: middle;
}

span.tumb-disc {
  font-size: 12px;
}

.user-post-message {
  text-align: left;
  margin: auto;
}

ul.header-menu {
  display: flex;
  font-size: 14px;
}

.header-menu li {
  padding: 10px;
  width: 190px;
  display: block;
  text-align: center;
}

.header-menu li + li {
  position: relative;
}
.header-menu li + li::before {
  content: "";
  display: block;
  height: 1em;
  border-left: 1px solid #999;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

img.pop {
  width: 200px;
}

p.pop {
  text-align: center;
  color: gray;
  font-size: 0.8rem;
}

.CheckboxInput {
  display: flex;
  align-items: center;
  cursor: pointer;
}
label.CheckboxInput {
  display: flex !important;
}

.CheckboxInput-Input {
  margin: 0;
  width: 0;
  opacity: 0;
}
.CheckboxInput:hover > .CheckboxInput-DummyInput {
  background: #cccccc;
}
.CheckboxInput-Input:focus + .CheckboxInput-DummyInput {
  background: #cccccc;
}
.CheckboxInput-Input:checked + .CheckboxInput-DummyInput {
  background: #a9ecf3;
}
.CheckboxInput-Input:checked + .CheckboxInput-DummyInput::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35%;
  height: 2px;
  transform: translate(-5px, 6px) rotateZ(-135deg);
  transform-origin: 1px 1px;
  background: #ffffff;
}
.CheckboxInput-Input:checked + .CheckboxInput-DummyInput::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 2px;
  transform: translate(-5px, 6px) rotateZ(-45deg);
  transform-origin: 1px 1px;
  background: #ffffff;
}
.CheckboxInput-DummyInput {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4) inset;
  margin: 10px;
}
.CheckboxInput-LabelText {
  display: block;
  font-weight: bold;
}

.list-top {
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    margin: 0;
    text-align: end;
  }
  .list-button {
    text-align: end;
  }
  a {
    width: 200px;
  }
  .page-title {
    text-align: center;
  }
}

@media (max-width: 750px) {
  .list-top {
    display: -webkit-box;
    width: 100%;

    a {
      width: 140px;
    }
  }
  .list-button {
    text-align: center;
    margin: 0 10px !important;
  }
  h2.page-title {
    margin: auto;
    text-align: center;
  }
}
