.product_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  .product {
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: solid 1px #e0e0e0;
    overflow: hidden;
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    .product_image {
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .product_info {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .product_price {
        &::after {
          content: "円";
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        & > * {
          flex: 1 1 auto;
        }
      }
    }
  }
}
