.master {
  /* pc 768px以上 */
  .wrap {
    /* サイドメニューとバナーエリアの基点に設定 */
    position: relative;
    width: 90%;
    // width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  /* PCメニュー */
  .gnavi-wrap {
    width: 100%;
    margin: 0 auto;
  }

  .gnavi-lists {
    display: flex;
  }

  .gnavi-list {
    width: 20%;
    height: 60px;
    background-color: #c2d1db;
    position: relative;
    transition: all 0.3s;
  }

  .gnavi-list:hover {
    background-color: #578aac;
  }

  .gnavi-list:not(:first-child)::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }

  .gnavi-list:hover::before {
    background-color: #578aac;
  }

  .gnavi-list a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #1b4059;
    font-size: 15px;
    letter-spacing: 0.05em;
    font-weight: 600;
    transition: all 0.3s;
  }

  .gnavi-list:hover a {
    color: #fff;
  }

  .dropdown-lists {
    display: none;
    /*デフォルトでは非表示の状態にしておく*/
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 99;
  }

  .gnavi-list:hover .dropdown-lists {
    display: block;
    /*Gナビメニューにホバーしたら表示*/
  }

  .dropdown-list {
    background-color: #265574;
    height: 60px;
    transition: all 0.3s;
    position: relative;
  }

  .dropdown-list:not(:first-child)::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #3492d1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .dropdown-list:hover {
    background-color: #003558;
  }

  .dropdown-list a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    position: relative;
  }

  .dropdown-list a::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(135deg);
    position: absolute;
    right: 15px;
    top: calc(50% - 5px);
  }

  .dropdown-lists {
    visibility: hidden;
    /*デフォルトでは非表示の状態にしておく*/
    opacity: 0;
    /*不透明度0*/
    transition: all 0.3s;
    /*表示の変化を0.3秒に指定*/
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
  }

  .gnavi-list:hover .dropdown-lists {
    visibility: visible;
    /*Gナビメニューにホバーしたら表示*/
    opacity: 1;
    /*不透明度1*/
  }

  .header-logout {
    text-align: end;
    padding: 2% 4%;
    display: flex;
  }

  .master h1 {
    width: 80%;
    text-align: left;
    padding: 0% 2%;
  }

  .master-logout {
    width: 20%;
  }

  .master img {
    width: 15%;
  }

  /* メインカラム */
  .main {
    width: 500px;
    margin-left: 230px;
    /* サンプル用に高さを指定 
    background-color: #ccc;*/
    margin: 10px auto;
  }

  h2 {
    border-bottom: double 5px #949fb5;
    font-size: 1.5rem !important;
    margin: 50px 0 !important;
  }

  h2:after {
    display: none;
  }

  h3 {
    margin: 0 0 5px 0 !important;
  }

  h4 {
    margin: 1% 0 !important;
    color: #5a5a5a;
    font-size: 0.8rem !important;
  }

  p {
    // margin: 0 0 2% 0 !important;
    font-weight: normal;
  }

  .boxcolor {
    background: #edf2f4 !important;
  }
  .btn {
    margin: 5% 0 10% 0 !important;
  }

  .btn.top {
    margin: 0 !important;
  }

  .btn span {
    margin: 0 2% !important;
  }

  input.save,
  input.add,
  input.edit,
  input.duplication,
  input.detail,
  input.confirm {
    background: #69afc9 !important;
  }

  .btn .back {
    background-color: #b6bec1;
  }

  table {
    color: #5a5a5a;
    width: 100%;
    border-collapse: separate;
  }
  th,
  td {
    padding: 5px 10px !important;
  }
  /*th{    width: 20%;}*/

  .information-box table tr {
    border-bottom: 0.5px solid rgb(194, 202, 206);
  }

  table tr {
    background: rgb(244, 246, 247);
  }
  table tr:nth-child(odd) {
    background: #fff;
  }

  .information-box.line {
    border-bottom: 0.5px solid rgb(194, 202, 206);
  }
  .information-box.line input,
  .information-box.line p,
  .information-box.line img,
  .information-box.line textarea {
    margin-bottom: 2% !important;
  }

  caption {
    text-align: center !important;
  }

  .main table td {
    padding: 5px 5px 5px 0;
  }

  input.save,
  input.add,
  input.edit,
  input.duplication,
  input.detail {
    border: none;
    background: #85cde7;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 0 10px;
  }
  .cp_ipselect.cp_sl {
    border: 2px solid #949fb5 !important;
  }

  input.delete {
    border: none;
    background-color: #f13e6b !important;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 10px 0;
  }

  .btn-flat-member {
    display: inline-block;
    padding: 0.3em 1em;
    text-decoration: none;
    color: #82afcc;
    border: solid 2px #82afcc;
    border-radius: 3px;
    transition: 0.4s;
    width: 200px;
    text-align: center;
  }

  .btn-flat-member:hover {
    background: #82afcc;
    color: white;
  }

  i.fa.fa-caret-right {
    color: #82afcc;
    margin-right: 10px;
  }

  i.fa.fa-caret-right:hover {
    color: white;
  }

  .message-form {
    padding: 10px 0;
  }

  .btn.message {
    margin: 5% 0 0 0 !important;
  }

  @media screen and (max-width: 750px) {
    input.save,
    input.add {
      margin: 0 10px;
    }

    .btn .back {
      width: 70%;
      margin: 10px 0;
    }

    .btn input {
      width: 70%;
    }

    h2 {
      font-size: 1rem !important;
    }

    h3 {
      font-size: 0.8rem !important;
    }
  }

  p.situation {
    padding: 0 20px;
  }

  .master-list {
    text-align: center;
  }

  .master-list.title {
    font-weight: bold;
  }

  /* セレクトメニュー */
  .cp_ipselect {
    overflow: hidden;
    width: 50%;
    margin: 2em auto;
    text-align: center;
  }

  .cp_ipselect select {
    width: 100%;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .cp_ipselect select::-ms-expand {
    display: none;
  }

  .cp_ipselect.cp_sl {
    position: relative;
    border-radius: 2px;
    border: 2px solid #82afcc;
    border-radius: 5px;
    background: #ffffff;
    margin: 0;
  }

  .cp_ipselect.cp_sl::before {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #82afcc;
    pointer-events: none;
  }

  .cp_ipselect.cp_sl select {
    padding: 8px 38px 8px 8px;
    color: #5a5a5a;
  }

  input.wide {
    border: 2px solid #949fb5;
    border-radius: 5px;
  }

  input.wide.category {
    width: 300px;
  }

  .cp_ipselect.cp_sl {
    border: 2px solid #949fb5 !important;
  }

  .cp_ipselect.point {
    width: 100%;
  }

  label,
  textarea {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  textarea {
    padding: 10px;
    max-width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 2px solid #949fb5;
    width: 100%;
  }

  th img.icon {
    width: 100px;
  }
  // label {
  //   display: block;
  //   margin-bottom: 10px;
  // }

  img.icon {
    width: 300px;
  }

  .contents-list li {
    list-style-type: decimal;
    color: #5a5a5a;
    margin: 0 5%;
  }

  @media screen and (max-width: 750px) {
    input.wide.category {
      width: 200px;
    }
  }

  /* スマホ 767px以下 */
  @media only screen and (max-width: 767px) {
    .wrap {
      /* position: relative;を解除 */
      position: static;
      width: 90%;
    }

    /* メインカラム */
    .main {
      width: 100%;
      /* サンプル用に高さを指定 */

      margin-left: 0;
      margin-bottom: 30px;
    }

    /* バナーエリア */
    .banner-area {
      /* position: absolute;を解除 */
      position: static;
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .main {
      width: 100%;
      /* サンプル用に高さを指定 
        background-color: #ccc;*/
      margin: 10px auto;
    }

    .main img {
      width: 100%;
    }
  }

  /* バナーエリア詳細 */

  .footer {
    background-color: #c2d1db !important;
  }
}
