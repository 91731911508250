.input {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: solid;
  border-color: skyblue;
  border-width: 2px;
  border-radius: 5px;

  &.fullWidth {
    width: calc(100% - 20px);
    &.noPadding {
      padding: 0;
      width: calc(100%);
    }
  }

  &.overflowXscroll {
    overflow-x: scroll;
  }
  &.overflowYscroll {
    overflow-y: scroll;
  }
  &.row {
    flex-direction: row;
  }
  &.grow {
    flex-grow: 1;
  }
  &.noPadding {
    padding: 0;
  }
  // input type dateに関する設定
  // &[type="date"]::-webkit-inner-spin-button,
  // &[type="date"]::-webkit-calendar-picker-indicator {
  //   display: none;
  //   -webkit-appearance: none;
  // }
}
