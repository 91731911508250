.creator {
  /* メインカラム */

  h2 {
    font-size: 1.2rem !important;
  }

  h3 {
    margin: 0 0 2% 0;
  }

  h3.list-add {
    width: 100%;
  }
  p {
    margin: 0 0 2% 0;
  }

  .information-box h3 {
    margin: 0 0 1% 0 !important;
  }

  .btn {
    margin: 4% 0 !important;
  }

  .btn .back {
    background-color: #b6bec1;
  }
  .information-box.list-add {
    text-align: center;
  }

  table {
    color: #5a5a5a;
  }

  .main table td {
    padding: 5px 5px 5px 0;
  }

  .information-box table tr {
    border-bottom: 0.5px solid rgb(206, 222, 230);
  }

  table tr {
    background: #eff9fc;
  }
  table tr:nth-child(odd) {
    background: #fff;
  }

  input.save,
  input.add,
  input.edit,
  input.detail {
    border: none;
    background: #85cde7;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 10px;
  }

  input.save:hover,
  input.add:hover,
  input.edit:hover,
  input.detail:hover {
    background: #79bbd3;
  }
  input.add {
    width: 180px;
  }

  input.duplication {
    border: none;
    background: #92a5d3;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 0 10px;
  }

  input.delete {
    border: none;
    background-color: #f13e6b !important;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 10px 0;
  }

  th.product-name {
    width: 300px;
  }
  .more-button {
    text-align: end;
    padding: 0 0 5% 0 !important;
  }
  .detail-btn,
  .add-btn,
  .delete-btn,
  .edit-btn,
  .duplication-btn {
    width: 80px;
  }

  @media screen and (max-width: 768px) {
    div.sp-btn-flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flex-submit {
      text-align: center;
      width: 100% !important;
    }

    .flex-submit2 {
      text-align: center;
      width: 90px !important;
    }

    input.save,
    input.add {
      margin: 0 10px;
    }
    .btn .back {
      margin: 10px 0;
    }
    h2 {
      font-size: 1rem !important;
    }
    h3 {
      font-size: 0.8rem !important;
    }

    .information-box th,
    .information-box td {
      padding: 5px !important;
    }
    .information-box th {
      background: #d9f1fb;
    }
    .information-box table {
      margin: auto;
      width: 100%;
    }

    .information-box table th,
    .information-box table td {
      border-bottom: none;
      display: block;
    }
    .information-box table td {
      width: 100%;
      text-align: center;
    }

    td.detail-btn {
      text-align: center;
      margin: 4% 0;
    }

    table tr {
      background: #fff;
    }

    input.save,
    input.add,
    input.edit,
    input.detail,
    input.add,
    input.delete,
    input.duplication {
      width: 50%;
    }

    .detail-btn,
    .add-btn,
    .delete-btn,
    .edit-btn,
    .duplication-btn {
      text-align: center;
      width: 100%;
    }

    th.product-name {
      width: 100%;
    }
  }

  p.situation {
    padding: 0 20px;
  }

  /* セレクトメニュー */
  .cp_ipselect {
    overflow: hidden;
    width: 100%;
    margin: 2em auto;
    text-align: center;
  }

  .cp_ipselect select {
    width: 100%;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .cp_ipselect select::-ms-expand {
    display: none;
  }

  .cp_ipselect.cp_sl {
    position: relative;
    border-radius: 2px;
    border: 2px solid skyblue;
    border-radius: 5px;
    background: #ffffff;
    margin: 0;
    width: 50%;
  }

  .cp_ipselect.cp_sl::before {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid skyblue;
    pointer-events: none;
  }

  .cp_ipselect.cp_sl select {
    padding: 8px 38px 8px 8px;
    color: #5a5a5a;
  }

  input.wide {
    border: 2px solid skyblue;
    border-radius: 5px;
  }

  input.wide.category {
    width: 300px;
  }

  label,
  textarea {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
  textarea {
    padding: 10px;
    max-width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 2px solid skyblue;
    width: 70%;
    resize: none;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  img.icon {
    width: 50%;
  }

  .contents-list li {
    list-style-type: decimal;
    color: #5a5a5a;
    margin: 0 5%;
  }

  @media screen and (max-width: 750px) {
    input.wide.category {
      width: 200px;
    }

    .wide,
    .cp_ipselect {
      width: 100% !important;
    }

    textarea {
      width: 90%;
    }
  }
  .wrap {
    width: 80%;
  }

  /* バナーエリア */
  .banner-area {
    /* 右端に設置 */
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
  }

  .banner-area div {
    margin-bottom: 20px;
  }

  .banner-area div a {
    display: block;
    height: 70px;
    line-height: 70px;
    background-color: #333;
    text-decoration: none;
    text-align: center;
    color: #fff;
  }

  /* スマホ 767px以下 */
  @media only screen and (max-width: 768px) {
    .wrap {
      /* position: relative;を解除 */
      position: static;
      width: 90%;
    }

    /* メインカラム */
    .main {
      width: 100%;
      /* サンプル用に高さを指定 */

      margin-left: 0;
      margin-bottom: 30px;
    }

    /* サイドメニュー */
    .side-menu {
      /* position: absolute;を解除 */
      position: static;
      width: 100%;
      margin: 30px 0;
    }

    /* バナーエリア */
    .banner-area {
      /* position: absolute;を解除 */
      position: static;
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .main {
      width: 100%;
      /* サンプル用に高さを指定 
        background-color: #ccc;*/
      margin: 10px auto;
    }

    .main img {
      width: 100%;
    }
  }

  /* バナーエリア詳細 */

  li.user-name {
    font-weight: bold;
    color: #85cde7;
  }
}
