.wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
  .image {
    object-fit: cover;
    height: 200px;
    width: 200px;
  }
  .text {
    color: #5e5e5e;
  }
  .buttons {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
    & > * {
      flex-grow: 1;
      background-color: #f58eb8;
    }
    @media screen and (max-width: 750px) {
      width: 50%;
    }



  }


}
