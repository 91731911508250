.products {
  display: flex;
   flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px;
  gap: 10px;
}
.product {
  flex: 0 0 20%;
  padding: 1.25%;
  border-radius: 10px;
  background: #edf8ff;
  box-shadow: 0 4px 8px rgba(124, 137, 158, 0.08);
  .imgContainer {
    width: 100%;
    position: relative;
    &:before {
      content: " ";
      display: block;
      padding-top: 100%;
    }
    .img {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    margin: 0;
    text-align: center;
  }
  @media all and (min-width: 768px) {
  }
}
