.text {
  white-space: pre-wrap;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  word-wrap: break-word;
  color: #5a5a5a;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 750px) {
  flex-shrink: 1;}

  &.small {
    font-size: 12px;
  }
  &.large {
    font-size: 20px;
  }
  &.center {
    text-align: center;
  }
  &.bold {
    font-weight: bold;
  }
  &.gray {
    color: #3d3d3d;
  }
  &.blue {
    color: skyblue;
  }
  &.white {
    color: white;
  }
  &.red {
    color: #cd3101;
  }
  &.pink {
    color: #f086d7d7;
  }
  &.fullWidth {
    width: calc(100% - 20px);
  }
  &.grow {
    flex-grow: 1;
  }
  &.shrink {
    flex-shrink: 1;
  }
  &.inline {
    display: inline;
  }
  &.flex {
    display: flex;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.alignCenter {
    align-items: center;
  }
  &.backgroundBlue {
    background-color: #85cde7;
  }
  &.newLine {
    white-space: pre-line;
    word-wrap: break-word;
  }
}
