.container {
  display: flex !important;
  flex-direction: row;
  padding: 10px;
  border: solid;
  border-color: skyblue;
  border-width: 2px;
  border-radius: 5px;
  gap: 10px;
  overflow-x: scroll;

  .tag {
    display: flex;
    flex: 0 0 auto;
    border-radius: 5px;
    padding: 0 5px;
    background-color: skyblue;
    color: white;
    font-size: 12px;
    font-weight: bold;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .xicon {
      color: white;
      font-size: 14px;
      cursor: pointer;
      & > * {
        color: white;
      }
    }
  }

  .input {
    display: inline;
    flex: 1 1 auto;
    border: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
