.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  .question {
    margin: 0;
  }
  .answer {
    margin: 0;
  }
}
