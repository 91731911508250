.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  gap: 100px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none;
  }
  .key {
    flex: 0 0 150px;
    font-weight: bold;
  }
  .value {
    flex: 1 1 auto;
    font-weight: normal;
  }
}
