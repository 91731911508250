.div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;

  &.width95 {
    width: 95%;
  }
  &.width70L {
    // 大きい画面の時70%の横幅になる
    @media screen and (min-width: 750px) {
      width: 75%;
    }
  }

  &.card {
    background: white;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  }
  &.maxWidth750 {
    max-width: 750px;
  }

  &.justifyCenter {
    justify-content: center;
  }
  &.alignCenter {
    align-items: center;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.alignEnd {
    align-items: flex-end;
  }

  &.quarter {
    width: 25%;
  }
  &.half {
    width: 50%;
  }

  &.fullWidth {
    width: 100%;
  }
  &.round {
    border-radius: 10px;
  }

  &.backgroundLightblue {
    background-color: #edf8ff;
  }

  &.backgroundGray {
    background-color: #eee;
  }

  &.backgroundWhite {
    background-color: white;
  }

  &.overflowXscroll {
    overflow-x: scroll;
  }
  &.overflowYscroll {
    overflow-y: scroll;
  }
  &.row {
    flex-direction: row;
  }
  &.noPadding {
    padding: 0;
  }
  &.padding50 {
    padding: 50px;
  }
  &.backgroundGrayOdd:nth-child(2n) {
    background-color: #f4f6f7;
  }
  &.wrap {
    flex-wrap: wrap;
    margin: 40px 0;
  }
  &.overflowHidden {
    overflow: hidden;
  }
  &.grow {
    flex-grow: 1;
  }
  &.shrink {
    flex-shrink: 1;
  }
  &.borderTop {
    border-top: solid 1px #e5e5e5;
  }
  &.borderBottom {
    border-bottom: solid 1px #e5e5e5;
  }
}
